import React, { useEffect} from "react";
import './aboutus.css'

function AboutUs() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

  return (
    <div className="aboutUsPage">
      <div className="aboutus-content">
        <h1 className='aboutUsPageHeading'>WHAT WE OFFER</h1>
        <img className="aboutus-image" src="/footer/tiger3.png" alt="tigerImage"/>
        <div className="aboutUs">
            <div className="aboutUs-text">
                <p>Welcome to India Bharat Travel, your ultimate travel companion for exploring the world’s most incredible destinations!
                We believe that travel is not just about reaching a destination; it's about immersing yourself in the experience, 
                uncovering hidden gems, and making memories that last a lifetime.
                </p>

                <p>At India Bharat Hub, our mission is to inspire and guide travelers of all kinds by providing a wealth of information to make every journey unforgettable.
                    Whether you're planning a weekend escape or an adventure abroad, we’ve curated the best places to visit, in-depth itineraries, 
                    and travel tips to help you make the most of your trip.
                </p>
                <p>What We Offer:
                    <ol>
                        <li><span className="bold">Top Destinations:</span> Discover the must-visit spots from bustling cities to serene landscapes and everything in between. 
                            Our expertly chosen destinations are designed to help you find inspiration for your next adventure.
                        </li>
                        <li><span className="bold">State Highlights:</span> Explore the unique charm of different regions with our state-by-state guides. 
                            From historic landmarks to natural wonders, each state offers its own story, and we’re here to show you the best it has to offer.
                        </li>
                        <li><span className="bold">Detailed Itineraries:</span> Our comprehensive itineraries provide you with all the details needed for a well-rounded trip. 
                            With recommended stops, tips for timing, and local insights, you can travel with ease, knowing you’re hitting the highlights.
                        </li>
                        <li><span className="bold">Travel Tips & Tricks:</span> Our travel tips section covers everything from budgeting advice and packing hacks to cultural insights and safety tips, 
                            ensuring that you're prepared for every aspect of your journey.
                        </li>
                    </ol>
                </p>
                <p>At India Bharat Hub, we’re passionate about bringing you closer to the places, people, and experiences that make travel truly rewarding. 
                    Join us as we explore the world, one adventure at a time. Happy travels!
                </p>
            </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;