import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './placesView.css';
import axios from 'axios';
import LazyLoad from 'react-lazyload';

function useFetchAllPlacesForPlaceParent( placeparent){   
    const [placeData, setPlaceData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    useEffect( () => {
        const fetchData= async()=>{
            setPlaceData([]);
            
            try{
                console.log("process.env.PLACES_URL: "+process.env.REACT_APP_PLACES_PARENT_URL);
                const response = await axios.get(process.env.REACT_APP_PLACES_PARENT_URL+`${placeparent}`);
                console.log("response: "+response.data);
                if(response.data){
                    setPlaceData(response.data);
                }
            }catch (error){
                console.error("error fetching data : ",error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        }
        fetchData();
    }, [placeparent]);
    
    return {placeData, loading, error};
}
function useGenerateSignedImages(placesListFromDB) {
    const [placesList, setPlacesList] = useState([]);
    console.log("placesListFromDB: "+placesListFromDB.length);
    
    useEffect(() => {
        const fetchImagesFromS3 = async () => {
            if(placesListFromDB && placesListFromDB.length === 0){
                setPlacesList([]);
                return;
            }
            const updatedPlacesList = await Promise.all(
                
                placesListFromDB.map(async (place) => {
                    console.log("place: "+place.placeName);
                    try {
                        let url = '';
                        if (place.placeImage) {
                            url=`${process.env.REACT_APP_PLACES_IMAGES_URL}`+place.placeImage;
                            console.log("url:" + url); 
                        }
                        return { ...place, placeImage: url };
                    } catch (error) {
                        console.error('Error fetching image from S3:', error);
                        return place;
                    }
                })
            );
            setPlacesList(updatedPlacesList);
        };
        
        fetchImagesFromS3();
    }, [placesListFromDB]);
    return placesList;
}

function PlacesParentPage() {
    const { placename } = useParams();
    
    const { placeData: placesListFromDB, loading, error }= useFetchAllPlacesForPlaceParent(placename);
    console.log("placesListFromDB size: "+placesListFromDB);
    var getSignedUrlForImages= useGenerateSignedImages(placesListFromDB);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;
  return (
    <div className='placesPage'>
      <h1 className='placePageHeading'>Places to visit in {placename}</h1>
        <div className="placesList">
                
                {getSignedUrlForImages.map (place => (
                    <div className="placeDiv">
                        <div className="placeImageDiv"> 
                            <LazyLoad>
                                <img  className="placeImage" src={place.placeImage} alt={place.placeName} />
                            </LazyLoad>
                        </div> 
                        {console.log("placename: "+place.placeName+"  :: placeImage displaying:" +place.placeImage)}
                        <div className="placeName"> {place.placeName} </div> 
                        <div  className="placeDescription"> {place.placeDescription} </div> 
                    </div>
                ))
            }
        </div>
    </div>
  );
}

export default PlacesParentPage;