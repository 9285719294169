import React from 'react';
import './states.css'
import { useNavigate } from 'react-router-dom';
import { useStateData } from '../context/stateContext';


export function RenderStatesNavBar({ isVisible, toggleVisibility }){
    console.log("inside render states navbar");
    const navigate= useNavigate();
    const data = useStateData();
    
    function fetchAllPlaces(stateId, stateName){
        if(isVisible)
            toggleVisibility();
        navigate(`/places/${stateId}/${stateName}`)
    }

    return (
            <div className={`states-container ${isVisible ? '' : 'hidden'}`}>
                <div className={`navbar ${isVisible ? '' : 'hidden'}`}>
                    
                    {data && Array.isArray(data) && (
                        data.map (state => (
                            <button key={state.stateId} className="stateName" onClick= { () => fetchAllPlaces(state.stateId, state.stateName) }> {state.stateName} </button>
                        ))
                        
                    )}
                </div>
                <button className="toggle-button" onClick={toggleVisibility}>
                    {isVisible ? '<' : 'Explore States'}
                </button>
            </div> 
    )
}
