import "./siteTitle.css"
import siteLog from './images/indiaBharatTravel.png'
import { useNavigate } from "react-router-dom";

export function RenderSiteTitle(){

    const navigate = useNavigate();

    const goToHome = () => {
        navigate('/');
    };
    return (
        <div className="titleDiv">
            <div className="siteTitle">
                <img src={siteLog} alt="logo" className="siteLogo"></img>
                <div className="siteName" onClick={goToHome} style={{ cursor: 'pointer' }}>
                    INDIA BHARAT TRAVEL
                </div>
            </div>
            <div className="social-links">
                <a href="https://www.instagram.com/indiabharattravel/" target="_blank" rel="noopener noreferrer" className="social-icon instagram">
                    <i className="fab fa-instagram"></i>
                </a>
                <a href="https://www.youtube.com/@indiabharattravel" target="_blank" rel="noopener noreferrer" className="social-icon youtube">
                    <i className="fab fa-youtube"></i>
                </a>
            </div>
        </div>
    )
}