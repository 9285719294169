import React, { useState, useEffect} from 'react';
import { Link } from 'react-router-dom'; 
import './frontpage.css'
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import { useStateData } from '../context/stateContext';

const images = [
    '/background/greybrownbackground.png',
    '/background/newbackground.png',
    '/background/blackbrownbackground.jpg',
];

function RenderFrontPage({ isVisible,toggleVisibility }){
    const [places, setPlaces] = useState([]); 
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isDropdownVisible, setDropdownVisible] = useState(false);
    const [destination, setDestination] = useState('');
    const [days, setDays] = useState('');
    const [error, setError] = useState('');
    const [animation, setAnimation] = useState('');
    
    const navigate= useNavigate();
    const states = useStateData();

    const filteredStates = states?.filter((state) => state.stateName.toLowerCase().includes(destination.toLowerCase()));

    // Show dropdown on input focus for state and hide when clicked outside
    const handleInputFocus = () => setDropdownVisible(true);
    const handleInputBlur = () => setDropdownVisible(false);

    
    //for small animation in the middle of the page
    useEffect(() => {
        const interval = setInterval(nextImage, 5000); // Change image every 5 seconds
        return () => clearInterval(interval); // Cleanup on component unmount
    }, []);
    
    // for places parent animation
    const nextImage = () => {
        setAnimation('exit');
        setTimeout(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
            setAnimation(''); // Reset animation after image change
        }, 1000);
    };
    const prevImage = () => {
        setAnimation('exit');
        setTimeout(() => {
            setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
            setAnimation('');
        }, 1000);
    };

    //for fetching placesparent to show at the bottom of the page
    useEffect(() => {
        const fetchPlaces = async () => {
            try {
                const response = await axios.get(process.env.REACT_APP_TOP_PLACES_URL);
                setPlaces(response.data || []); // Ensure data is an array
            } catch (error) {
                console.error("Error fetching data:", error);
                setPlaces([]); // Handle error by setting an empty array
            }
        };
        fetchPlaces();
    }, []);

    // Only create placesWithImages if places are available and not empty
    const placesWithImages = places.length > 0 ? places.slice(0, 5).map(place => ({
        name: place.placeParentName,
        imageUrl: `${process.env.REACT_APP_PLACES_IMAGES_URL}${place.placeParentName}.png`
    })) : [];

    useEffect(() => {
        let next = document.querySelector('.next');
        let prev = document.querySelector('.prev');

        const handleNext = () => {
            const items = document.querySelectorAll('.item');
            document.querySelector('.sliding-window').appendChild(items[0]); // Move the first item to the end
        };
    
        const handlePrev = () => {
            const items = document.querySelectorAll('.item');
            document.querySelector('.sliding-window').prepend(items[items.length - 1]); // Move the last item to the front
        };
    
        // Attach event listeners
        next.addEventListener('click', handleNext);
        prev.addEventListener('click', handlePrev);

        // Clean up event listeners when the component is unmounted
        return () => {
            next.removeEventListener('click', handleNext);
            prev.removeEventListener('click', handlePrev);
        };
    }, []);

    const handleSubmit = () => {
        console.log('Destination:', destination);
        console.log('Days:', days);
        if (!destination || !days) {
            setError("Both State/UT and Days are required!");
            return;
        }
        // Add any additional logic you need here
        if(isVisible){
            toggleVisibility();
        }
        navigate(`/tour/${destination}/${days}`)
    };
    const handleInputChange = (e) => {
        console.log("handling input change, value selected: "+e.target.value);
        setDestination(e.target.value);
        setDropdownVisible(true); // Show dropdown when typing
    };
    const handleOptionClick = (stateName) => {
        console.log("handling click: "+stateName);
        setDestination(stateName);      // Set input value to selected state
        setDropdownVisible(false);      // Hide dropdown after selection
    };

    return (
            <div className='frontpage-container'>
                <div className='frontpage-1'>
                    <div className='frontpage-title'>LET US GENERATE THE DREAM ITENARY FOR YOU!</div>
                    <div className='frontpage-search'>
                        <div className="destination" onBlur={handleInputBlur} onFocus={handleInputFocus}>
                            <p>Where To?</p>
                            <input 
                                type="text" 
                                className="search-input" 
                                placeholder="State/UT" 
                                value={destination} 
                                onChange={handleInputChange}
                                onFocus={handleInputFocus} 
                            />
                                {isDropdownVisible && filteredStates && (
                                    <div className="dropdown">
                                        {filteredStates.map((state) => (
                                            <div className='dropdown-item-outer'>
                                                <div
                                                    onMouseDown={() => handleOptionClick(state.stateName)}
                                                    className="dropdown-item"
                                                >
                                                    {state.stateName}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                        </div>
                        <div className="days">
                            <p>How many Days?</p>
                            <input type="text" className="search-input" placeholder="days" value={days} onChange={(e) => setDays(e.target.value)}
                            onKeyDown={(e) => e.key === 'Enter' && handleSubmit()}/>
                        </div>
                        {error && <div className="error-message">{error}</div>}
                        <div className='submit-button-container'>
                            <button type="submit" className="submit-button"  onClick={handleSubmit}>Find Now</button>
                        </div>
                    </div>
                </div> 
                <div className='frontpage-2'>
                    <div className='frontpage2-innerdiv'> 
                        <div className='frontpage2-left'>
                            <div className='slideshow'>
                                <img src={images[currentIndex]} alt="Slideshow" className={`slideshow-image ${animation}`}  key={currentIndex}/>
                                <button className='prev-button' onClick={prevImage}>❮</button>
                                <button className='next-button' onClick={nextImage}>❯</button>
                            </div>
                            <div className='dots'>
                            {images.map(index => (
                                <span
                                    key={index}
                                    className={`dot ${currentIndex === index ? 'active' : ''}`}
                                    onClick={() => setCurrentIndex(index)}
                                ></span>
                            ))}
                        </div>
                        </div>
                        <div className='frontpage2-right'>
                            <div className='frontpage2-title'>Unleash your wanderlust and let us craft personalized itineraries tailored just for you.</div>
                            <div className='frontpage2-desc'>
                                At India Bharat Travel, we understand that no two journeys are the same. Whether you’re planning a quick weekend getaway or an epic cross-country adventure, our platform helps you create the perfect itinerary for the places you want to visit. 
                                Simply tell us where and when, and we’ll handle the rest. With seamless planning tools, customized recommendations, and expert insights, your dream vacation is just a few clicks away.
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div className='frontpage-3'>
                    <div className='frontpage3-innerdiv'> 
                        <div className='sliding-window'>
                            {placesWithImages.map((place) => (
                                <div 
                                className='item' 
                                style={{
                                    backgroundImage: `url(${place.imageUrl})`,
                                }}>
                                    <div className='content'>
                                        <div className='name'>{place.name}</div>
                                        <button className='submit-button' onClick= { () => navigate(`/places/name/${place.name}`) }> See More</button>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="button">
                            <button className="prev"><i className="fa-solid fa-arrow-left"></i></button>
                            <button className="next"><i className="fa-solid fa-arrow-right"></i></button>
                        </div>
                    </div>
                </div>
                <div className='frontpage-4'>
                    <div className='frontpage4-innerdiv'> 
                        <div className='footer-links'>
                            <Link to="/about-us" className='footer-link'>About Us</Link>
                            <Link to="/privacy-policy" className='footer-link'>Privacy Policy</Link>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default RenderFrontPage;