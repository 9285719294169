import './App.css';
import { RenderStatesNavBar } from './navbar/states';
import { RenderSiteTitle } from './common/renderSiteTitle';
import { BrowserRouter as Router, Route, Routes, useLocation  } from 'react-router-dom';
import PlacesPage from './places/placesView';
import { StateDataProvider} from './context/stateContext';
import RenderFrontPage  from './frontpage/frontpage';
import PlacesParentPage from './places/placesParent';
import TourSelectorPage from './tour/tourpage';
import ItenaryPage from './itenary/itenarypage';
import React, { useState, useEffect } from 'react';
import AboutUs from './footer/aboutus';
import PrivacyPolicy from './footer/privacyPolicy';
import ReactGA from 'react-ga4';

ReactGA.initialize('G-YZ1KZ6P5XQ'); 

function AppContent() {
  const location = useLocation();
  const isPlacesPage = location.pathname.startsWith('/places/');
  const [isVisible, setIsVisible] = useState(true);

  const toggleVisibility = () => {
    console.log("toggle visiblity called");
      setIsVisible(!isVisible);
  };
  useEffect(() => {
    ReactGA.send("pageview"); // Track the initial page load
  }, []);
  
  return (
  
    <div className={`app-container ${isPlacesPage ? 'black-background' : 'display-page'}`}>
      
      <StateDataProvider>
          <RenderSiteTitle />
          <div className="pagecontent">
            <RenderStatesNavBar isVisible={isVisible} toggleVisibility={toggleVisibility} />
            <Routes>
              <Route path='/' element={<RenderFrontPage isVisible={isVisible} toggleVisibility={toggleVisibility}/>} />
              <Route path='/places/:stateId/:stateName' element={<PlacesPage isVisible={isVisible} toggleVisibility={toggleVisibility}/>} />
              <Route path='/places/name/:placename' element={<PlacesParentPage isVisible={isVisible} toggleVisibility={toggleVisibility}/>} />
              <Route path='/about-us' element={<AboutUs isVisible={isVisible} toggleVisibility={toggleVisibility}/>} />
              <Route path='/privacy-policy' element={<PrivacyPolicy isVisible={isVisible} toggleVisibility={toggleVisibility}/>} />
              <Route path='/tour/:stateName/:days' element={<TourSelectorPage isVisible={isVisible} toggleVisibility={toggleVisibility}/>}/>
              <Route path='/itenary/:tourId' element={<ItenaryPage isVisible={isVisible} toggleVisibility={toggleVisibility}/>}/>
            </Routes>
          </div>
      </StateDataProvider>
    </div>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;