import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './itenaryPage.css';
import axios from 'axios';
import LazyLoad from 'react-lazyload';

function useFetchItenary( tourId){   
    const [itenaryData, setItenaryData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    useEffect( () => {
        const fetchData= async()=>{
            setItenaryData([]);
            
            try{
                console.log("process.env.TOUR_PLAN_URL: "+process.env.REACT_APP_ITENARY_URL+`${tourId}`);
                const response = await axios.get(process.env.REACT_APP_ITENARY_URL+`${tourId}`);
                if(response.data){
                    setItenaryData(response.data);
                }
            }catch (error){
                console.error("error fetching data : ",error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        }
        fetchData();
    }, [tourId]);
    
    return {itenaryData, loading, error};
}
function useGenerateSignedImages(itenaryDataReceived) {
    const [itenaryList, setItenaryList] = useState([]);
    console.log("itenaryDataReceived: "+itenaryDataReceived.length);
    
    useEffect(() => {
        const fetchImagesFromS3 = async () => {
            if(itenaryDataReceived && itenaryDataReceived.length === 0){
                setItenaryList([]);
                return;
            }
            const itenaryDataReturn = await Promise.all(
                itenaryDataReceived.map(async (itenary) => {
                    // Update each place with a signed image URL
                    const updatedPlacesList = itenary.placesList.map((place) => {
                        let url = '';
                        if (place.placeImage) {
                            url = `${process.env.REACT_APP_PLACES_IMAGES_URL}${place.placeImage}`;
                        }
                        return { ...place, placeImage: url };
                    });

                    // Return the itenary object with updated placesList
                    return { ...itenary, placesList: updatedPlacesList };
                })
            );
            const sortedItenaryList = itenaryDataReturn.sort((a, b) => a.day - b.day);
            setItenaryList(sortedItenaryList);
        };
        
        fetchImagesFromS3();
    }, [itenaryDataReceived]);
    return itenaryList;
}

function ItenaryPage(){ 
    const { tourId } = useParams();
    const [expandedDay, setExpandedDay] = useState(null); 
    const { itenaryData: itenaryDataReceived, loading, error }= useFetchItenary(tourId);
    var itenaryList= useGenerateSignedImages(itenaryDataReceived);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    const handleToggle = (day) => {
        if (expandedDay === day) {
          setExpandedDay(null); // Close if already expanded
        } else {
          setExpandedDay(day); // Expand the clicked day
        }
      };

  return (
    <div className='itenaryPage'>
      <h1 className='itenaryPageHeading'>Here's the Itenary for you</h1>
        <div className="itenaryListOuter">    
            <div className="itenaryDiv">      
                    {itenaryList && Array.isArray(itenaryList) && (
                        itenaryList.map (itenary => (
                            <div className='itenaryList'>
                                <div className='itenaryDayAndMap'>
                                    <div className='itenaryDay'>Day {itenary.day}</div>
                                    <div className="itenaryMapLink"><a  href={itenary.itenaryMapLink}> Map for Day {itenary.day}</a></div>
                                    <button onClick={() => handleToggle(itenary.day)} className='expandItenaryBtn'>
                                        {expandedDay === itenary.day ? '-' : '+'}
                                    </button>
                                </div>
                                
                                <div className="itenaryDescription"> {itenary.itenaryDescription}</div>
                                {expandedDay === itenary.day && (
                                <div className='placesDiv'>
                                    {itenary.placesList && Array.isArray(itenary.placesList) && (
                                        itenary.placesList.map((place,index) => (
                                            
                                            <React.Fragment>
                                                 <div key={index} className={`itenaryPlaceDiv ${index % 2 === 0 ? 'left' : 'right'}`}>
                                                    <div className="itenaryPlaceImageDiv"> 
                                                        <LazyLoad>
                                                            <img  className="itenaryPlaceImage" src={place.placeImage} alt={place.placeName} />
                                                        </LazyLoad>
                                                    </div> 
                                                    <div className="itenaryPlaceName"> {place.placeName} </div> 
                                                    <div  className="itenaryPlaceDescription"> {place.placeDescription} </div> 
                                                </div>
                                                {/* {index < itenary.placesList.length - 1 && (
                                                    <div className="carAnimation">
                                                    <div className='carIcon'><i class="fa-solid fa-car-side"></i></div>
                                                    <div className='roadLine'>-----------</div>
                                                    </div>
                                                )} */}
                                            </React.Fragment>
                                        ))
                                    )}
                                </div>
                                )}
                            </div>
                        ))
                        
                    )}
            </div> 
        </div>
    </div>
  ); 
}

export default ItenaryPage;