import React, { useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import './tourPage.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import LazyLoad from 'react-lazyload';

function useFetchAllTours(stateName, days) {
  const [tourData, setTourData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_TOUR_PLAN_URL}${stateName}/${days}`);
        if (response.data) {
          setTourData(response.data);
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [stateName, days]);

  return { tourData, loading, error };
}

function TourSelectorPage({isVisible, toggleVisibility}) {
  const { stateName, days } = useParams();
  const { tourData: tourDataReceived, loading, error } = useFetchAllTours(stateName, days);
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate= useNavigate();

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? tourDataReceived.length - 1 : prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === tourDataReceived.length - 1 ? 0 : prevIndex + 1));
  };

    function fetchItenary(tourId){
        console.log("fetching Itenary for tourId: "+tourId);
        if(isVisible){
          toggleVisibility();
        }
        navigate(`/itenary/${tourId}`)
    }
    
  return (
    <div className="tourPage">
      <div className="carousel">
      {console.log("tourData size: "+tourDataReceived.length)}
        {tourDataReceived && tourDataReceived.map((tour,index) => {
          
          const isActive = index === currentIndex;
          const isLeft = index === (currentIndex - 1 + tourDataReceived.length) % tourDataReceived.length;
          const isRight = index === (currentIndex + 1) % tourDataReceived.length;
        
            return(
              <div
              key={tour.tourId}
              className={`tourPageContent ${isActive ? 'active' : isLeft ? 'left' : isRight ? 'right' : ''}`}
            >
              {console.log("className added")}
                <div className='imageDiv'>
                <LazyLoad>
                    <img  className="circuitImage" src={`${process.env.REACT_APP_PLACES_IMAGES_URL}${tour.circuitImage}`} alt={tour.circuitImage} />
                </LazyLoad>
                </div>
                <h2>{tour.placesCircuit}</h2>
                <a href={tour.circuitLink} target="_blank" rel="noopener noreferrer">
                  Map Link
                </a>
                <div className='buttonDiv'>
                <button id={tour.tourId} className="tourSelectButton" onClick= { () => fetchItenary(tour.tourId) }> Fetch Itenary </button>
                </div>
            </div>
            )
      })}
      </div>
      <button className="carouselButton prevButton" onClick={handlePrev}>Prev</button>
      <button className="carouselButton nextButton" onClick={handleNext}>Next</button>
    </div>
  );
}

export default TourSelectorPage;
