import React, { useEffect} from "react";
import './privacyPolicy.css'

function PrivacyPolicy() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

  return (
    <div className="privacyPolicyPage">
      <div className="privacyPolicy-content">
        <h1 className='privacyPolicyPageHeading'>PRIVACY POLICY</h1>
        <div className="privacyPolicy-date"> Effective Date: 9th Nov 2024</div>
        <div className="privacyPolicy">
            <div className="privacyPolicy-text">
                <p>At India Bharat Hub, we value the privacy of our visitors and are committed to protecting any information you may share with us. 
                    This privacy policy explains how we handle any personal data and provides transparency regarding our practices.
                </p>

                <p className="p-heading">1. Information We Collect
                </p>
                <p>We do not collect any personal information, including but not limited to names, email addresses, phone numbers, or payment details, 
                    from our visitors.
                     Your privacy is important to us, and we do not require or store any personal data to use our website.
                </p>
                <p className="p-heading">2. Cookies and Tracking Technologies
                </p>
                <p>Our website may use cookies to improve the user experience, track website usage, and analyze site performance. 
                    These cookies do not collect personal information; they are used solely to enhance the functionality and performance of our website.
                     You can adjust your browser settings to refuse cookies or alert you when a cookie is being sent.
                </p>
                <p className="p-heading">3. Third-Party Services
                </p>
                <p>We may link to third-party websites (e.g., booking services, travel agencies, or external content). 
                    Please note that these third-party websites have their own privacy policies.
                     We do not control the content or the privacy practices of these sites, and we encourage you to review their privacy policies before sharing any personal information.
                </p>
                <p className="p-heading">4. Data Security
                </p>
                <p>While we do not collect or store any personal data, we are committed to maintaining the security of our website to prevent unauthorized access or disclosure. 
                    We implement industry-standard security measures to ensure the integrity and confidentiality of your data.
                </p>
                <p className="p-heading">5. Children's Privacy
                </p>
                <p>Our website is not intended for individuals under the age of 13. We do not knowingly collect any personal information from children.
                     If you are a parent or guardian and believe we have collected information from a child under the age of 13, please contact us so we can take appropriate action.
                </p>
                <p className="p-heading">6. Changes to this Privacy Policy
                </p>
                <p>We may update this Privacy Policy from time to time. 
                    Any changes will be posted on this page with the updated effective date. 
                    We encourage you to review this policy periodically to stay informed about how we protect your privacy.
                </p>
            </div>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;