import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios'

const StateDataContext = createContext();

export const StateDataProvider = ({ children }) => {
    const [data, setData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(process.env.REACT_APP_STATES_URL);
                setData(response.data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);

    return (
        <StateDataContext.Provider value={data}>
            {children}
        </StateDataContext.Provider>
    );
};
export const useStateData = () => useContext(StateDataContext);